@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #5699ff;
}

/* ====================================================== */
/* ============ MUI customization and fixes ============= */
/* ====================================================== */

.MuiTableCell-root {
  font-size: 0.7rem !important;
}

@media screen and (max-width: 500px) {
  .MuiTableCell-root {
    font-size: 0.6rem !important;
  }
}

.MuiFab-root {
  font-size: 0.8rem !important;
}

.MuiAutocomplete-option[aria-selected='true'] {
  background-color: #b6e4ca !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiInput-root {
  padding-right: 56px;
}

.MuiDivider-root[class*='MuiOutlinedInput-root'] {
  border-color: rgba(0, 0, 0, 0.05);
}

.MuiOutlinedInput-input {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.MuiDateCalendar-root {
  height: auto !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  padding-top: 0px !important;
}

/* ====================================================== */
/* =========== Custom classes and overwrites ============ */
/* ====================================================== */

#notistack-snackbar {
  white-space: pre-wrap;
}

.bouncing-dots > .dot {
  display: inline-block;
  animation-name: bouncing;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
.bouncing-dots > .dot:nth-child(2) {
  animation-delay: 250ms;
}
.bouncing-dots > .dot:nth-child(3) {
  animation-delay: 500ms;
}

@keyframes bouncing {
  0% {
    transform: none;
  }

  20% {
    transform: translateY(-0.25em);
  }

  25% {
    transform: translateY(-0.25em);
  }

  45% {
    transform: none;
  }
}

@keyframes flash-appear {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* This changes the collor of the clustering in the google maps api */
gmp-advanced-marker svg {
  fill: #EE7372;
}
